.Rfq {
    &__row {
        display: flex;
        align-items: stretch;
    }

    &__tableControls {
        display: flex;
        // justify-content: flex-end;
        justify-content: space-between;
    }
    &__tableControlsLeft {
        display: flex;
        gap: 1rem;
    }
}

.Cards {
    display: flex;
    margin-bottom: 1rem;

    &__card {
        flex-grow: 1;
        &:not(:last-child) {
            margin-right: 1rem;
        }

        // &:last-child {
        //     flex-grow: 0.5;
        // }
    }

    &__createNewCard {
        background-color: #7800ef;
        color: white;
    }
}

.Draft {
    background-color: #fdebd8;
    color: #f7a64f;
    border-radius: 4px;
    padding: 0.5rem;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
}

.sortableHeader {
    cursor: pointer;
    user-select: none;

    .sortIndicator {
        justify-content: center;
        display: inline-block;
        font-size: 0.8em;
        color: #333;
        margin-left: 0.3rem;
    }
}
