.Select {
    &__inputField {
        font-size: 1.4rem;
    }

    label {
        font-size: 1.4rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
        margin-top: 1rem;
    }
}
