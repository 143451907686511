@use '../../assets/scss/variables.scss' as variables;
@use '../../assets/scss/mixins.scss' as mixins;

.Sidebar {
    background-color: #f8f9fb;
    display: flex;
    flex-direction: column;
    min-width: fit-content;
    // height: calc(100vh - variables.$header-height);
    height: 100vh;
    // position: fixed;
    // left: 0;
    // bottom: 0;
    border-radius: 0 5px 5px 0;
    padding: 0 1.6rem;

    &__iconContainer {
        display: flex;
        // color: white;
        color: #4a4a4a;
        align-items: center;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: variables.$header-height;
        padding: 0 1.2rem;

        span {
            display: inline-block;
            cursor: pointer;
        }
    }

    &__topNotExpanded {
        justify-content: center;
    }

    &__image {
        height: 3rem;
        width: fit-content;
        margin-right: 2rem;
    }

    &__bottomDeck {
        // background-color: violet;
        // align-self: flex-end;
        // justify-self: flex-end;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    &__expand {
        padding: 5px;
        border-radius: 6px;
    }
    &__expand:hover {
        background-color: #e8e8e8;
    }
}
