.toggle__switch {
    display: flex;
    flex-direction: column;
    width: fit-content;

    &__label {
        font-size: 1.4rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
        margin-top: 1rem;
    }

    &__wrapper {
        margin: 0.1rem 0;
        position: relative;
        input[type='checkbox'] {
            width: 41px;
            height: 21px;
            -webkit-appearance: none;
            appearance: none;
            outline: none;
            border-radius: 2rem;
            cursor: pointer;
            background-color: #8d8c8c;
            box-shadow: inset 0 0 2px #8d8c8c;
            transition: background-color 0.3s;

            &:disabled {
                cursor: not-allowed;
                background-color: #ccc;
            }

            &::before {
                content: '';
                position: absolute;
                width: 17px;
                height: 17px;
                border-radius: 50%;
                background: white;
                box-shadow: 0 0 2px #8d8c8c;
                top: 1.65px;
                left: 2px;
                transition: transform 0.3s;
            }

            &:checked {
                background-color: #1bbb6b;

                &::before {
                    transform: translateX(20px);
                }
            }
        }
    }

    &__msg {
        margin-top: 10px;
        font-size: 14px;
        color: #ff0000;
        white-space: nowrap;
    }
}
