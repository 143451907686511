.TableContent {
    width: 100%;
    background-color: white;
    border-radius: 12px;
    border-collapse: collapse;
    // min-width: 40rem;
    overflow: hidden;
    border: 1px solid #f0f2f5;
    // border-collapse: separate;
    border-spacing: 0;

    tr {
        border-bottom: 1px solid #e4dbdb;
    }

    thead tr {
        background-color: #f0f2f5;
    }

    tbody tr {
        background-color: #ffffff;
    }

    thead tr th {
        font-weight: 400;
        color: #5a5a5a;
    }

    thead tr th,
    tbody tr td {
        padding: 1rem;
        font-size: 1.4rem;
    }

    tbody tr:nth-child(even) {
        background-color: #f9fafb;
    }

    tbody tr td:nth-child(1),
    tbody tr td:nth-child(3) {
        font-weight: 500;
    }

    thead tr th:last-child,
    tbody tr td:last-child {
        text-align: right;
    }

    &__hoverable {
        tbody tr {
            text-align: left;
            cursor: pointer;
            position: relative;
            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 4px;
                border-radius: 0 16px 16px 0;
                background-color: transparent;
            }

            &:hover {
                background-color: rgba(112, 188, 255, 0.162);
                &::after {
                    background-color: rgb(140, 180, 140);
                }
            }
        }
    }
}
