.Rfq {
}

.Cards {
    display: flex;
    margin-bottom: 1rem;

    &__card {
        flex-grow: 1;
        &:not(:last-child) {
            margin-right: 1rem;
        }

        &:last-child {
            flex-grow: 0.5;
        }
    }

    &__createNewCard {
        background-color: #7800ef;
        color: white;
    }
}

.Table {
    width: 100%;
    background-color: white;
    border-radius: 12px;
    border-collapse: collapse;
    padding: 1rem;
    // min-width: 40rem;
    overflow-x: auto;

    tr {
        text-align: left;
    }

    thead tr th {
        font-weight: 400;
        color: #5a5a5a;
    }

    thead tr th,
    tbody tr td {
        padding: 1rem;
        font-size: 1.4rem;
    }

    tbody tr:nth-child(odd) {
        background-color: #f9f9f9;
    }

    tbody tr td:nth-child(1),
    tbody tr td:nth-child(3) {
        font-weight: 500;
    }

    thead tr th:last-child,
    tbody tr td:last-child {
        text-align: right;
    }
}
