.SupplierDetails {
    &__header {
        display: flex;
        align-items: flex-end;
        margin-bottom: 6rem;

        a {
            display: block;
            text-decoration: none;
            cursor: pointer;
        }

        p {
            margin: 0;
        }

        > div {
            max-width: 25rem;

            &:nth-child(1) {
                margin-right: 2rem;
            }
        }

        &__buttons {
            display: flex;
            margin-bottom: 2rem;

            button {
                padding-left: 2rem;
                padding-right: 2rem;
                font-size: 1.2rem;

                &:nth-child(1) {
                    margin-right: 1rem;
                    background-color: #202264;
                }

                &:nth-child(2) {
                    background-color: #f54e1a;
                }
            }
        }

        &__title {
            color: #ca9494;
            font-weight: bold;
            font-size: 2.4rem;
            margin: 1.5rem 0;
        }

        &__company {
            font-weight: bold;
            color: black;
            font-size: 2.4rem;
        }

        &__location {
            color: #ca9494;
            font-size: 2rem;
        }

        &__email {
            font-size: 1.6rem;
        }

        &__phone {
            font-size: 1.6rem;
        }

        &__pocTitle {
            color: #ca9494;
            font-size: 2rem;
        }

        &__pocName {
            font-size: 2rem;
            font-weight: bold;
        }

        &__pocEmail {
            font-size: 1.6rem;
        }
    }
}

.Cards {
    display: flex;
    margin-bottom: 1rem;

    &__card {
        flex-grow: 1;
        &:not(:last-child) {
            margin-right: 1rem;
        }

        &:last-child {
            flex-grow: 0.5;
        }
    }

    &__createNewCard {
        background-color: #7800ef;
        color: white;
    }
}

.Table {
    width: 100%;
    background-color: white;
    border-radius: 12px;
    border-collapse: collapse;
    padding: 1rem;
    // min-width: 40rem;
    overflow-x: auto;

    tr {
        text-align: left;
    }

    thead tr th {
        font-weight: 400;
        color: #5a5a5a;
    }

    thead tr th,
    tbody tr td {
        padding: 1rem;
        font-size: 1.4rem;
    }

    tbody tr:nth-child(odd) {
        background-color: #f9f9f9;
    }

    tbody tr td:nth-child(1),
    tbody tr td:nth-child(3) {
        font-weight: 500;
    }

    thead tr th:last-child,
    tbody tr td:last-child {
        text-align: right;
    }
}
