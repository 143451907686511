.ImageCarousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    &__main {
        position: relative;
        width: 100%;
        max-width: 500px;
        aspect-ratio: 16 / 9;
        overflow: hidden;
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.3s ease-in-out;
        }
    }

    &__control {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: rgba(0, 0, 0, 0.6);
        color: #fff;
        border: none;
        padding: 8px 16px;
        cursor: pointer;
        z-index: 2;
        font-size: 1.5rem;
        border-radius: 50%;
        transition: background 0.2s;

        &:hover {
            background: rgba(0, 0, 0, 0.8);
        }

        &Left {
            left: 10px;
        }

        &Right {
            right: 10px;
        }
    }

    &__thumbnails {
        display: flex;
        gap: 8px;

        &__thumbnail {
            width: 50px;
            height: 50px;
            border: 2px solid transparent;
            border-radius: 4px;
            overflow: hidden;
            cursor: pointer;
            transition: border-color 0.3s;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
        
            }

            &Active {
                border-color: #007bff;
            }
        }
    }
}
