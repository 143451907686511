.Badge {
    border-radius: 4px;
    padding: 0.5rem;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    background-color: white;
    color: black;
    height: fit-content;
}
