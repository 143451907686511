.customFileInput {
    display: flex;
    gap: 2rem;
    align-items: center;
    padding-bottom: 1.3rem;
    padding-top: 1.3rem;
}

.customFileInputLabel {
    color: #00b976;
    padding: 5px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: 2px solid #00b976;
    margin-left: 0.5rem;
}

.customFileInputLabel:hover {
    background-color: #00b976;
    color: #fff;
    border-color: #00b976;
}

.customFileInputField {
    display: none;
}

.customSelectedFileName {
    font-weight: bold;
}

.customFileInputButton {
    margin-top: 10px;
    background-color: #00b976;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.customFileInputButton:hover {
    background-color: #0056b3;
}
.BtnContainer {
    display: flex;
    gap: 1rem;
}
.CancelBtn {
    background-color: white;
    color: black;
    border: 2px solid darkgray;
}
.CancelBtn:hover {
    background-color: #f2f2f2;
}

.fileInputError {
    color: red;
}
