.Button {
    background-color: #00b976;
    color: white;
    // border-radius: 10rem;
    border: none;
    padding: 0.8rem 1.6rem;
    font-size: 1.4rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        display: inline-block;
        margin-right: 0.5rem;
    }
}
