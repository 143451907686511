.OrganizationCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    &__left {
        > p:nth-child(1) {
            font-size: 1.4rem;
            margin-bottom: 0.5rem;
        }
        > p:nth-child(2) {
            font-size: 1.8rem;
            font-weight: bold;
            margin: 0;
        }
    }

    &__right {
        padding: 1rem;
        background-color: white;
        border-radius: 7px;

        img {
            height: 2rem;
        }
    }
}
