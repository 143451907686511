.FormTextarea {
    &__input {
        border: 1px solid #a0aec0;
        font-size: 1.4rem;
        padding: 1.2rem;
        outline: none;
        display: block;
        width: 100%;
        border-radius: 8px;
    }

    &__msg {
        color: red;
        text-transform: capitalize;
        margin-top: 3px;
        font-weight: 500;
        display: block;
    }

    &__label {
        font-size: 1.4rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
        margin-top: 1rem;
    }
}
