.StepSlider {
    > * {
        padding: 0;
        margin: 0;
    }

    width: 100%;
    // height: 5px;
    display: flex;
    justify-content: space-between;
    border-radius: 1rem;
    align-content: center;
    position: relative;
    // background-color: yellow;

    &::before {
        content: '';
        position: absolute;
        background-color: #cccccc;
        height: 5px;
        width: 100%;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        border-radius: 1rem;
    }

    &::after {
        content: '';
        position: absolute;
        background-color: green;
        height: 5px;
        width: 100%;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        border-radius: 1rem;
    }

    span {
        z-index: 1;
        // transform: translateY(1rem);
        position: relative;
        cursor: pointer;
        transform: translateY(1.5rem);
        font-weight: bold;

        &::before {
            content: '';
            position: absolute;
            width: 1rem;
            height: 1rem;
            background-color: #cccccc;
            top: calc(50% - 1.5rem);
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
        }

        &:last-child {
            &::before {
                left: 100%;
                transform: translate(-100%, -50%);
            }
        }

        &:first-child {
            &::before {
                left: 0;
                transform: translate(0, -50%);
            }
        }

        &:hover {
            &::before {
                // background-color: red;
                border: 2px solid black;
                box-sizing: content-box;
            }
        }
    }
}
