@use '/node_modules/bootstrap/scss/bootstrap-grid.scss';
.BtnContainer {
    display: flex;
    gap: 1rem;
}
.CancelBtn {
    background-color: white;
    color: black;
    border: 2px solid darkgray;
}
.CancelBtn:hover {
    background-color: #f2f2f2;
}
