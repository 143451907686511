@use '../../assets/scss/variables.scss' as variables;

.Header {
    min-height: variables.$header-height;
    background-color: white;
    display: flex;
    align-items: center;
    border: 1px solid #f0f1f5;

    &__welcomeContainer {
        display: flex;
        align-items: center;
        justify-content: flex-end; /* Align to the right */

        & > .Header__welcome {
            margin-right: 10px; /* Add some space between "Hi user" and the organization name */
        }

        &__organizationName {
            color: gray !important; /* Apply grayscale styling to the organization name */
        }
    }

    &__image {
        padding: 2rem 2rem 0 2rem;
        height: 8rem;
    }

    &__title {
        font-weight: 600;
        font-size: 2rem;
        margin: 0;
    }

    &__container {
        width: 90%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        position: relative;
        z-index: 2;
    }

    > div {
        position: relative;
        right: 15px;
    }

    &__welcomeWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__welcome {
        width: fit-content;
        display: flex;
        align-items: center;
        font-size: 1.6rem;
        cursor: pointer;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
        font-weight: bold;
        margin-bottom: 0;
        :nth-child(2) {
            font-size: 25px;
            margin-right: -1.5rem;
        }
    }

    &__dropdown {
        position: absolute;
        right: 0;
        width: 15rem;
        top: 100%;
    }
    .logoalign {
        margin-top: 20px;
        margin-left: 20px;
        /* margin: 20px; */
    }
}
