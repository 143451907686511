.Container {
    display: flex;
    gap: 10px;
    margin: 20px 0 20px 0;
    &__arrowBtn {
        height: 3rem;
        display: flex;
        padding: 10px 16px;
        border-radius: 20px;
        transition: all 0.3s ease;
        cursor: pointer;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        border: none;
        background-color: transparent;
    }
    &__arrowBtn:hover {
        background-color: #f5f5f5;
    }
}
.Container__arrow {
    transition: transform 0.3s ease;
}

.Container__arrowBtn:hover .Container__arrow {
    transform: scaleX(1.8);
}
