@use '/node_modules/bootstrap/scss/bootstrap-grid.scss';

.User {
    &__Title {
        font-size: 1.8rem;
        font-weight: bold;
        margin-top: 2rem;
    }
}

.separator {
    border: 1px solid rgb(211, 211, 211);
    width: 98%;
    margin: 3rem auto;
}
.Details {
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
    font-weight: bold;
}
.CreateUserBtn {
    margin-top: 2rem;
}
