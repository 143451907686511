.sidebar {
    background-color: #f5f4f6;
    display: flex;
    flex-direction: column;
    min-width: fit-content;

    height: 100vh;

    border-radius: 0 5px 5px 0;
    padding: 0 1.6rem;
    margin-right: 20px;

    /* position: fixed; */
    top: 70px;
    left: 212px;
    bottom: 0;
    width: 200px;
}

.sidebarWrapper {
    color: #615a5a;
}

.sidebarList {
    list-style: none;
    padding-left: 0;
    margin-left: 0px;
}
.sidebarListitem {
    padding-left: 2rem;
    width: 100%;
    text-decoration: none;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 2rem;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
    align-items: self-start;
}

.sidebarListitem > img {
    width: 20px;
    color: lightslategray;
}
.sidebarListitem > h4 {
    padding-top: 2px;
}

.sidebarListitem:hover {
    width: 100%;
    background-color: #e7e5e5;
    color: black;
}

.link {
    text-decoration: none;
    color: inherit;
}

.sidebarListitem.selected {
    background-color: #47e696;
    color: black;
}
