@use '/node_modules/bootstrap/scss/bootstrap-grid.scss';

.Doc {
    &__container {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
    &__searchContainer {
        display: flex;
        position: relative;
    }

    &__searchBoxWrapper {
        background-color: white;
        border-radius: 8px;
        border: 1px solid #dcdcdc;
        overflow: hidden;
        display: flex;
        align-items: center;
        margin-right: 1rem;
    }

    &__searchBox {
        background-color: transparent;
        outline: none;
        height: 100%;
        border: none;
        font-size: 1.4rem;
        padding-right: 1rem;
    }

    &__searchBoxIcon {
        margin: 0 1rem;
    }
}
.Filters {
    display: flex;
    align-items: stretch;
    height: 4rem;
}
.customFileInput {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.customFileInputLabel {
    width: 100%;
    height: 200px;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    font-size: 24px;
    color: #555555;
    border: 2px #c3c3c3 dashed;
    border-radius: 12px;
}

.customFileInputField {
    display: none;
}

.customFileInputButton {
    margin-top: 10px;
    background-color: #00b976;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.customFileInputButton:hover {
    background-color: #0056b3;
}
.operation {
    display: flex;
    gap: 1rem;
}
