.Settings {
    margin-left: -5.6%;
    display: flex;
    justify-content: flex-start;
}
.rightContainer {
    padding: 1.8rem 22rem;
    width: 100%;
    background-color: white;
    margin-top: 20px;
}
.Categories {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &__Registered {
        margin-top: 1rem;
    }
    &__Title {
        font-size: 1.5rem;
    }
}
.Tab {
    &__Content {
        display: flex;
        // padding-left: 21rem;
        font-size: small;
        padding-top: 1rem;
    }
}
.Filter {
    font-size: medium;
}
.multi {
    width: 80%;
}
.Btn {
    margin-top: 1rem;
}
