.TableButton {
    width: 0;
    cursor: pointer;
}
.TableButton > svg:hover {
    color: grey;
    transform: scale(1.2) rotate(15deg);
}
.TableButton svg {
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
}
