.Card {
    &__dropdown {
        z-index: 1;
        display: none;
        list-style-type: none;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        margin: 0;
        min-width: max-content;
        // width: 100%;
        // font-size: inherit;
        font-size: 1.4rem;
        border-radius: 8px;
        overflow: hidden;
        align-items: center;
        padding: 0;

        li {
            cursor: pointer;
            padding: 0.7rem 1rem;
            border-radius: 8px;
            &:hover {
                background-color: #f2f2f2;
            }
        }

        &_active {
            display: block;
        }
    }
}
