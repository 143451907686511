.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // opacity: 0;
    // visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 999;

    .content {
        position: relative;
        padding: 20px;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        width: fit-content;
        min-width: 40%;
        max-width: 70%;
        max-height: 90%;
        overflow-y: auto;
        margin: 0 auto;
        // overflow: hidden;
        @media screen and (max-width: 768px) {
            max-width: 100%;
            max-height: 100%;
            overflow-y: scroll;
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .title {
        font-size: 24px;
        font-weight: bold;
        margin: 0;
    }

    .closeIcon {
        cursor: pointer;
        color: #888;
        font-size: 24px;
        transition: color 0.3s ease;

        &:hover {
            color: #f00;
        }
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.content {
    z-index: 1;
    background-color: #fff;
    padding: 3.5rem 3rem;
    border-radius: 1.4rem;
    max-width: 55rem;
    max-height: 90vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background-color: #e4e4e4;
        border-radius: 10rem;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #d4aa70;
        border-radius: 10rem;
    }
}

.title {
    margin-top: 0;
    font-size: 2.4rem;
}

.body {
    // margin-bottom: 10px;
    margin: 2rem 0;
    padding: 0 20px 0 20px;
}

.close {
    padding: 8px 12px;
    border: none;
    background-color: #eee;
    border-radius: 4px;
    cursor: pointer;
}
