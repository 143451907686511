@use '../../assets/scss/mixins.scss' as mixins;



@keyframes water-flow {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
}

.Login {
    min-height: 100vh;
    display: flex;
    border-radius: 20px;

    &__sections {
        display: flex;
        flex-grow: 1;
        

        &__left,
        &__right {
            flex-basis: 50%;
            align-items: stretch;
            padding: 2rem;
           
        }

        &__left {
            display: flex;
            flex-direction: column;

            @include mixins.tablet {
                flex-basis: 100%;
            }
        }

        &__right {
            // background: linear-gradient(to bottom right, #56ab2f, #a8e063);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2%;
            background: linear-gradient(120deg, #1bbb6b 25%, #021e65 50%, rgb(30, 25, 110)75%);
            background-size: 200% 200%;  /* Larger background for smooth wave effect */
            animation: water-flow 10s ease-in-out infinite;
            height: 100%;
            margin: 20px;
           

            @include mixins.tablet {
                display: none;
            }
        }
    }
    &__logo{
        // margin-top: 10px;
        height: 55px;
        width: 180px;
    }

    &__logoContainer {
        display: flex;
        justify-content: space-between;

        h3 {
            cursor: pointer;
            margin-top: 20px;
        }
    }

    &__contentContainer {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__welcomeContainer{
        margin-right: 130px;
    }

    &__title {
        text-align: left;
        margin-bottom: 4rem;

        &__main {
            display: block;
            color:black;
            font-size: 3rem;
            margin-top: 10px;
            font-weight: bold;
            text-align: left;
           
        }

        &__sub {
            display: block;
            font-weight: 400;
            font-size: 2rem;
            margin-bottom: 1.6rem;
            color: #718096;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 42rem;
        // border-radius: 20px;
    }

    &__formGroup {
        width: 100%;
        // border-radius: 20px;

        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    &__formRow {
        display: flex;
        justify-content: space-between;
        margin: 1.6rem 0;
    }

    &__formInput {
        border: 1px solid #a0aec0;
        font-size: 1.6rem;
        padding: 1.2rem;
        outline: none;
        display: block;
        width: 100%;
    }

    &__formRememberContainer {
        display: flex;
        align-items: center;
        font-size: 1.6rem;

        label {
            margin-left: 0.5rem;
            font-weight: bold;
        }
    }

    &__formForgotPassword {
        display: block;
        white-space: nowrap;
        font-size: 1.6rem;
        font-weight: bold;
        text-decoration: none;
    }

    &__formBtn {
        padding: 1.4rem;
        font-size: 1.6rem;
        background-color: #0038ff;
        font-weight: bold;
        color: white;
        border: none;
        margin-bottom: 1.4rem;
        display: flex;
        justify-content: center;
    }

    &__formRegisterText {
        
        margin: 20px;
        margin-left: 0;
        text-align: center;
        font-size: 1.6rem;
        text-align: left;
    }

    &__carouselContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        text-align: center;
        max-width: 50rem;
        margin: 0 auto;
        color: white;

        .carousel .slide {
            background: none;
        }

        .carousel .control-dots .dot {
            background: white;
        }

        .carousel .control-prev.control-arrow:before,
        .carousel .control-next.control-arrow:before {
            border-top-color: white;
            border-right-color: white;
        }
    }

    &__carouselSlide {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        text-align: center;

        img {
            max-width: 100%;
            height: auto;
            margin-bottom: 2rem;
        }
    }

    &__carouselText {
        font-size: 2.4rem;
        margin-bottom: 2rem;
    }

    &__carouselDescription {
        font-size: 1.4rem;
        max-width: 40rem;
        margin: 0 auto;
    }
}
.welcometext{
    text-align: left;
    font-size: 1.5rem;
}

.ToggleSwitch {
    > * {
        margin: 0;
        padding: 0;
    }

    background-color: rgb(88, 86, 86);
    border-radius: 20rem;
    overflow: hidden;
    position: relative;
    color: white;
    margin-bottom: 4rem;

    span {
        display: inline-block;
        border: none;
        background-color: transparent;
        z-index: 2;
        position: relative;
        padding: 1.2rem 1rem;
        text-align: center;
        width: 11.5rem;
        cursor: pointer;
        font-size: 1.4rem;
    }

    &::after {
        content: '';
        width: 50%;
        height: 100%;
        position: absolute;
        left: 50%;
        background-color: #1bbb6b;
        border-radius: 20rem;
        z-index: 1;
        transition: all 300ms;
    }

    &--supplierActive {
        &::after {
            left: 0;
        }
    }

    &--organizationActive {
        &::after {
            left: 50%;
        }
    }

    @media (max-width: 442px) {
        flex-direction: column;
        width: 100%;

        span {
            width: 100%;
            font-size: 1.2rem;
            padding: 1rem 0;
            margin-left: 0;
        }

        &::after {
            display: none; 
        }

        &--supplierActive span:first-child {
            background-color: #1bbb6b; 
        }

        &--organizationActive span:last-child {
            background-color: #1bbb6b; 
            align-items: center;
        }
    }

    @media (min-width: 769px) and (max-width: 1020px) {
        flex-direction: column;
        width: 100%;

        span {
            width: 100%;
            font-size: 1.2rem;
            padding: 1rem 0;
            margin-left: 0;
        }

        &::after {
            display: none; 
        }

        &--supplierActive span:first-child {
            background-color: #1bbb6b; 
        }

        &--organizationActive span:last-child {
            background-color: #1bbb6b; 
            align-items: center;
        }
    }
}




.ortext > h3 {
    margin-top: 20px;
}

.btn2 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.text {
    h3 {
        margin-top: 40px;
        margin-left: 150px;
    }
}
.buyer{
   margin-left: 40px;
}
.submit{
    background: linear-gradient(to right, #021e65, #2160a3);
}
