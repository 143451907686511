.orgSettings {
    display: flex;
    justify-content: flex-start;
    margin-left: -5.6%;
}

.Tab {
    display: flex;
    flex-direction: column;
    padding: 3rem;
    width: 100%;
    height: fit-content;

    &__Content {
        display: flex;
        // padding-left: 21rem;
        font-size: small;
        padding-top: 1rem;
    }
    &__Sectwo {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        &__row {
            width: 100%;
        }
    }
    &__Secthree {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
    }
}

.Filter {
    font-size: medium;
}

.Btn {
    margin-top: 1rem;
}
