@use '/node_modules/bootstrap/scss/bootstrap-grid.scss';

.CreateRFQ__FormWrapper {
    max-height: 70vh;
    overflow-y: auto;
}

.CreateRFQ__buttonWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
}

.CreateRFQ__buttonGroup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}
.modal_actionButton {
    display: flex;
    gap: 1rem;
}
.CancelBtn {
    background-color: white;
    color: black;
    border: 2px solid darkgray;
}
.CancelBtn:hover {
    background-color: #f2f2f2;
}
.CreateRFQ__tertiaryTitle {
    margin-top: 1rem;
}
.CreateRFQ__note {
    background-color: #f2f2f2;
    border-radius: 8px;
    width: fit-content;
    padding: 0.5rem 1rem;
}
