@import '../SupplierProductCatalogueView/SupplierProductCatalogueView.module.scss';
@import '../Products/Products.module.scss';
.Logo{
    margin-left: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
   height: 50px;
   width: 160px;

}
.LogoContainer{

     display: flex;
     justify-content: space-between;

     h3 {
         cursor: pointer;
         margin-top: 20px;
     }
 
}