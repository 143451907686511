.Back {
    width: fit-content;
    margin-right: -5.2rem;
    margin-left: 2rem;
    // margin-top: 1rem;
    border-radius: 7px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 6px;

    &:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.096);
    }
}
