.Layout {
    &__container {
        display: flex;
    }

    &__contentContainer {
        flex-basis: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    &__content {
        overflow-y: scroll;
        background-color: white;
        flex-grow: 1;

        > div {
            width: 90%;
            margin: 0 auto;
            height: 100%;
        }
    }
}
