@use '/node_modules/bootstrap/scss/bootstrap-grid.scss';

.PrintIcon {
    cursor: pointer;
   
    transition: color 0.3s;

    &:hover {
        color: red; 
    }
}

.Cards {
    display: flex;
    margin-bottom: 1rem;

    &__card {
        flex-grow: 1;
        &:not(:last-child) {
            margin-right: 1rem;
        }

        // &:last-child {
        //     flex-grow: 0.5;
        // }
    }

    &__createNewCard {
        background-color: #7800ef;
        color: white;
    }
}

.Table {
    &__row {
        display: flex;
        align-items: stretch;
    }

    &__tableControls {
        display: flex;
        // justify-content: flex-end;
        justify-content: space-between;
    }

    &__btnWrapper {
        position: relative;

        ul {
            position: absolute;
            top: 110%;
            right: 0.5rem;
        }
    }
}

.SellerModal {
    &__selectWrapper {
        margin-bottom: 1rem;
        padding-bottom: 7rem;
    }
}
