.SupplierSelect {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    label {
        font-weight: bold;
        margin-bottom: 8px;
    }

    select {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: #fff;
        font-size: 14px;
        color: #333;
        transition: border-color 0.2s;

        &:focus {
            border-color: #007bff;
            outline: none;
        }
    }
}

.CompareButton {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background-color: #0056b3;
    }
}

.lowest-value {
    background-color: #e0f7fa;
    font-weight: bold;
    border: 1px solid #b2ebf2;
    position: relative;

    &:after {
        content: 'Lowest';
        position: absolute;
        right: 5px;
        top: 5px;
        font-size: 0.75rem;
        color: #006064;
    }

    &:hover {
        background-color: #b2ebf2;
    }
}

.tableHeader {
    cursor: pointer;
}
.empty_records {
    text-align: center;
    font-size: 20px;
    font-weight: lighter;
}

.table-row {
    border-bottom: 1px solid #eee;

    &:nth-child(even) {
        background-color: #f7f7f7;
    }
}
