// PurchaseOrderDetails.module.scss
@import '../OrganizationSupplierDetails/OrganizationSupplierDetailsNew.module.scss';
.Request {
    &__container {
        display: flex;
        gap: 1rem;
    }
    &__titleContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        font-size: 2rem;
        font-weight: 600;
    }

    &__text {
        font-size: 1.5rem;
        display: flex;
        justify-content: space-between;
        color: grey;
    }
    &__buttonWrapper {
        &:not(:last-child) {
            margin-right: 1rem;
        }
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
    }
    &__EditBtn {
        color: #1bbb6b;
        border: 2px solid #1bbb6b;
        background-color: white;
    }
    &__EditBtn:hover {
        background-color: #f2f2f2;
    }

    &__Popup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    &__Popup__content {
        // background-color: #fff;
        padding: 20px;
        border-radius: 8px;
        // box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        width: 80%;
    }

    &__Popup__items {
        max-height: 70vh;
        overflow-y: auto;
        padding-right: 10px;
    }
}

.closeButton {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    background-color: #00b976;
    color: #fff;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    transition: background-color 0.3s;

    &:hover {
        background-color: #02794d;
    }
}
.details {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    margin: auto;
    gap: 20px;
}

.Card {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
}

.Request__organizationDetails {
    background-color: #231709;
    padding: 1rem 1.5rem;
    color: white;
    border-radius: 10px 10px 0 0;
    font-size: 1.2rem;
    font-weight: bold;
}

.Request__organizationContents {
    padding: 1rem 1.5rem;
}

.Request__organizationName {
    font-size: 1.8rem;
    font-weight: bold;
}

.Request__text {
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    margin-bottom: 0.5rem;
}

.Request__text span:nth-child(2) {
    font-size: 1.6rem;
    color: black;
    margin-top: 0.25rem;
}

// Media query for larger screens
@media (min-width: 768px) {
    .Request__text {
        flex-direction: row;
        justify-content: space-between;
    }

    .Request__text span:nth-child(2) {
        margin-top: 0;
    }
}
