.Request {
    &__buttons {
        display: flex;
    }

    &__title {
        font-size: 20px;
        color: #333;
        margin-bottom: 8px;
        font-weight: bold;
    }

    &__buttonWrapper {
        &:not(:last-child) {
            margin-right: 1rem;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        margin: 2.4rem 0;
    }

    &__rfqId {
        font-size: 2rem;
        font-weight: 600;
    }

    &__descriptionTitle {
        font-size: 1.4rem;
        // font-weight: 500;
    }

    &__text {
        font-size: 1.5rem;
        display: flex;
        justify-content: space-between;
        color: grey;
    }
    &__text span:nth-child(2) {
        text-transform: capitalize;
        color: black;
    }

    &__organizationDetails {
    }

    &__organizationName {
        font-size: 1.4rem;
        font-weight: 600;
    }

    &__content {
        display: flex;
        gap: 1rem;
    }
}

.BidConfirmationDialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.BidConfirmationDialogContent {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    text-align: center;

    p {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .BidConfirmationButtons {
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-top: 20px;

        button {
            padding: 8px 16px;
            font-size: 14px;
        }
    }
}
.tlContent .tlHeader,
.tlContent .tlBody {
    padding-left: 25.6px;
    border-left: 2px solid gainsboro;
    margin-left: 5px;
}

.tlBody {
    padding-bottom: 0.6rem;
    font-size: 14px;
}

.tlAuthor {
    font-size: 15px;
    color: #333;
    font-weight: bold;
}

.tlTime {
    font-size: 11px;
    padding-left: 0.7rem;
    font-weight: lighter;
}
.tlHeader {
    position: relative;
    display: grid;
}

.tlMarker {
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50% / 50%;
    background: gainsboro;
    left: -0.9rem;
    top: 0.4rem;
    transform: translate(50%, -50%);
}
.commentForm {
    gap: 1rem;
    align-items: center;
    display: flex;
}
.Note {
    margin-top: 0.9rem;
    background-color: #f2f2f2;
    border-radius: 8px;
    width: fit-content;
    padding: 0.5rem 1rem;
}
.comment {
    width: 100%;
}
.timeline__Content {
    max-height: 12rem;
    overflow-y: auto;
}

.TemplateHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.TemplateActions {
    display: flex;
    justify-content: start;
    padding: 10px;
}

.CloseButton {
    cursor: pointer;
}
.CloseButton:hover {
    color: red;
}

.error {
    color: red;
    font-size: 0.9rem;
    margin-top: 10px;
}
