.Layout {
    &__container {
        display: flex;
    }

    &__contentContainer {
        flex-basis: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    &__content {
        overflow-y: scroll;
        // background: linear-gradient(
        //     180deg,
        //     rgba(217, 217, 217, 0.37) 0%,
        //     rgba(217, 217, 217, 0) 100%
        // );
        background-color: white;
        // padding: 1rem 0;
        flex-grow: 1;

        > div {
            width: 90%;
            margin: 0 auto;
            height: 100%;
        }
    }
}
