.EnquiryNotification {
    padding: 20px;
}

.EnquiryCardsContainer {
    display: flex;
    overflow-x: auto;
    gap: 20px;
    padding: 20px 0;
}

.EnquiryCard {
    min-width: 300px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex-shrink: 0;
}

.ProductList {
    margin-top: 10px;
}

.ProductItem {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.CreatedAt {
    margin-top: 10px;
    font-size: 0.9em;
    color: #888;
}
.NoEnquiriesMessage {
    text-align: center;
    margin-top: 20px;
    font-size: 1.2em;
    color: #888;
}
.EnquiryCards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); 
    gap: 16px;
}

.EnquiryCard {
    box-sizing: border-box;
}
p{
    font-size: medium;
}
.Delete_btn{
    background: rgb(211, 70, 70);
}
.Btn{

    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-top:auto;
    margin-bottom: 5px;
    
  
}


.FilterContainer {
    margin-top: 20px;
    display: flex;
    gap: 1rem;
  
  
}


.EnquiryList {
    margin-top: 20px;
   
}

