@use '/node_modules/bootstrap/scss/bootstrap-grid.scss';

.CreateRFQ {
    &__titleWrapper {
        max-width: 100%;
        margin: auto;
        margin-top: 2rem;
    }

    &__title {
        margin-top: 0rem;
        font-size: 1.8rem;
        font-weight: bold;
    }

    &__subtitle {
        font-size: 1.3rem;
        color: #898989;
        margin-bottom: 3rem;
    }

    &__container {
        background-color: white;
        border-radius: 12px;
        padding: 2rem 3rem;
        max-width: 100%;
        margin: 0 auto;
        margin-bottom: 4rem;
    }

    &__stepSlider {
        max-width: 100%;
        margin: 0 auto;
        margin-bottom: 3rem;
    }

    &__tableContainer {
        max-height: 400px;
        overflow-y: auto;
        margin-bottom: 20px;
    }

    &__popupContainer {
        height: 500px;
        min-width: 1000px;
        display: flex;
        flex-direction: column;
    }

    &__tableScroll {
        flex-grow: 1;
        overflow-y: auto;
        width: 100%;
    }
    &__checkbox {
        cursor: pointer;
    }
    &__inputbox {
        width: 100%;
        padding: 10px;
        border: 2px solid #ccc;
        border-radius: 7px;
        font-size: 14px;
        cursor: default;
    }

    &__horizontalRule {
        border: 1px solid rgb(211, 211, 211);
        width: 98%;
        margin: 3rem auto;
    }

    &__secondaryTitle {
        margin-bottom: 1.5rem;
        font-size: 1.8rem;
        font-weight: bold;
    }

    &__editIcon {
        cursor: pointer;
    }

    &__sellerButton {
        margin-bottom: 1rem;
    }

    &__buttonWrapper {
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        margin-top: 1.5rem;

        button {
            padding: 0.4rem 1rem;
            font-size: 1.2rem;
            font-weight: bold;
        }
    }

    &__searchWrapper {
        display: flex;
        align-items: stretch;
        height: 52px;
        margin-top: 1rem;
    }

    &__submit_btn {
        margin-top: 30px;
    }

    &__attachmentsWrapper {
        margin-top: 2rem;
    }

    &__tertiaryTitle {
        font-size: 1.6rem;
        font-weight: 500;
        margin-bottom: 1rem;
    }

    &__fullWidthButton {
        // margin-top: 1rem;
        // width: 80rem;
        // margin: 0 auto;
        // display: block;
        &__Cancel {
            background-color: white;
            color: black;
            border: 2px solid darkgray;
        }
        &__Cancel:hover {
            background-color: #f2f2f2;
        }
        &__Draft {
            color: #1bbb6b;
            border: 2px solid #1bbb6b;
            background-color: white;
        }
        &__Draft:hover {
            background-color: #f2f2f2;
        }
    }

    &__bidTypeWrapper {
        // display: flex;
        // align-items: center;
        margin-bottom: 1rem;
    }

    &__bidTypeInput {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
}
.ButtonContainer {
    display: flex;
    gap: 1rem;
}

.ModalContent {
    display: flex;
    flex-direction: column;
    max-height: 500px;
}

.TableScrollContainer {
    overflow-y: auto;
    margin-bottom: 20px;
}
