@use '../../assets/scss/mixins.scss' as mixins;
@use '../../assets/scss/variables.scss' as variables;
@use '/node_modules/bootstrap/scss/bootstrap-grid.scss';

.Register {
    // display: flex;
    height: 100vh;
    // overflow: hidden;

    &__sections {
        display: flex;
        flex-grow: 1;
        padding: 0 5rem;

        &__left,
        &__right {
            align-items: stretch;
        }

        &__left {
            flex-basis: 60%;
            display: flex;
            flex-direction: column;
            margin-right: 8rem;

            @include mixins.tablet {
                flex-basis: 100%;
                margin-right: 0;
            }
        }

        &__right {
            flex-basis: 40%;

            @include mixins.tablet {
                display: none;
            }
            height: calc(100vh - variables.$header-height);
        }
    }

    &__logoContainer {
        padding: 2rem 2rem 0 2rem;
    }

    &__contentContainer {
        display: flex;
        // background-color: yellow;
        height: 100%;
        flex-direction: column;
    }

    &__title {
        margin-top: 2rem;
        margin-bottom: 4rem;

        &__main {
            display: block;
            font-size: 2.8rem;
            margin-bottom: 0.5rem;
            font-weight: bold;
        }

        &__sub {
            display: block;
            font-weight: 400;
            font-size: 1.6rem;
            color: #718096;
        }
    }

    &__form {
        // display: flex;
        // flex-direction: column;
        width: 100%;
    }

    button {
        width: 100%;
    }

    &__formBtn {
        padding: 1.4rem;
        font-size: 1.6rem;
        background-color: #0038ff;
        font-weight: bold;
        color: white;
        border: none;
        margin-bottom: 1.4rem;
        display: flex;
        justify-content: center;
        width: 100%;
    }

    &__confirmText {
        // text-align: center;
        font-size: 1.2rem;
    }

    &__welcomeContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // height: 100%;
        // max-width: 80rem;
        margin: 0 auto;
        height: 90%;
        padding: 0 5rem !important;
        margin: 2rem 0;
    }

    &__welcomeText {
        font-size: 4rem;
        margin-bottom: 2rem;
        text-align: center;

        &__main {
            display: block;
            font-size: 2.8rem;
            font-weight: bold;
        }

        &__sub {
            display: block;
            font-weight: 400;
            font-size: 1.8rem;
            margin-bottom: 1.6rem;
        }
    }

    &__welcomeDescription {
        font-size: 1.4rem;
    }

    &__welcomeList {
        // list-style-type: none;
        // text-align: center;
    }

    &__welcomeListItem {
        font-size: 1.6rem;
        margin-bottom: 2.5rem;
        // position: relative;

        // &:not(:last-child) {
        //     &::after {
        //         content: "";
        //         width: 3rem;
        //         height: 5px;
        //         border-radius: 8px;
        //         background-color: green;
        //         position: absolute;
        //         left: 50%;
        //         transform: translate(-50%, 0);
        //         bottom: -1.5rem;
        //     }
        // }
    }

    &__welcomeIllustration {
        height: 18rem;
        margin-bottom: 5rem;
    }

    &__welcomeLink {
        font-weight: 600;
        text-align: center;
        font-size: 1.6rem;
        a {
            text-decoration: none;
        }
    }

    .InputWithError--mismatch {
        border-color: red;
    }

    .InputWithError__errorMsg--mismatch {
        color: red;
        font-size: 0.9rem;
    }
    .Register__formError {
        color: red;
        margin-top: 10px;
        font-size: 14px;
    }
    .Register__formGroup {
        select.Register__select {
            width: 100%;
            height: 47px;
            padding: 10px;
            border: 1px solid #a0aec0;
            border-radius: 7px;
            background-color: #fff;
            color: #333;
            font-size: 14px;
        }

        select.Register__selectError {
            border-color: red;
        }
    }
    .popup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    .popupContent {
        background-color: #fff;
        padding: 80px;
        border-radius: 15px;
        text-align: center;
    }

    .popup h2 {
        margin-top: 0;
    }

    .popup p {
        margin-bottom: 30px;
    }

    .closeButton {
        background-color: #5b00ff;
        color: #fff;
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
    }

    .Register__phoneInputGroup {
        display: flex;
        align-items: center;
    }

    .Register__countryCodeInput {
        width: 50px;
        margin-right: 10px;
    }
    .form-group {
        border: 1px solid #ced4da;
        padding: 5px;
        border-radius: 6px;
        width: auto;
    }

    .form-group:focus {
        color: #212529;
        background-color: #fff;
        border-color: #86b7fe;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
    }

    .form-group input {
        display: inline-block;
        width: auto;
        border: none;
    }

    .form-group input:focus {
        box-shadow: none;
    }
    .Register__passwordRequirements {
        margin-top: 10px;
    }

    .Register__passwordRequirements h5 {
        font-size: 8px;
        font-weight: bold;
    }

    .Register__passwordRequirements ul {
        list-style-type: disc;
        margin-top: 5px;
        margin-left: 20px;
    }

    .Register__passwordRequirements li {
        font-size: 10px;
        margin-bottom: 5px;
        color: green;
    }

    .Register__formGroup {
        position: relative;
    }

    .Register__passwordInput {
        position: relative;
        display: flex;
        align-items: center;
    }

    .Register__passwordInput input {
        padding-right: 280px;
    }

    .Register__passwordVisibility {
        position: absolute;
        right: 10px;
        cursor: pointer;
    }

    .Register__passwordIcon {
        font-size: 18px;
        color: #888;
    }
    .Register__passwordVisibility {
        background-color: transparent;
        color: black;
    }

    .Register__passwordVisibility:hover {
        background-color: transparent;
        color: black;
    }

    &__verifyModal {
        p {
            font-size: 1.4rem;
            margin-bottom: 1.5rem;
        }

        button {
            width: fit-content;
        }
    }

    &__headerLogo {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 999;
        // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

        padding: 10px 0;
        text-align: center;
    }

    &__scrollableContent {
        flex-grow: 1;
        overflow-y: auto;
    }
    @include mixins.Laptop {
        &__welcomeListItem {
            margin-bottom: 0.5rem;
            font-size: 1.4rem;
        }
        &__welcomeIllustration {
            height: 15rem;
            margin-bottom: 0.5rem;
        }
        &__welcomeText__main {
            font-size: 2rem;
        }
        &__welcomeText__sub {
            font-size: 1.4rem;
        }
    }
}
