.Dashboard {
    display: flex;
    margin-top: 2rem;
    &__select {
        border-radius: 100%;
        margin-top: 4px;
        margin-left: 3rem;

        &__value {
            border: 1px solid #e3e3e3;
            border-radius: 100px;
            align-items: center;
            padding: 8px 16px;
            gap: 4px;
        }
    }
}

.Onboard {
    max-width: 20rem;
    margin-left: 2rem;
    cursor: pointer;

    &__title {
        padding: 1.2rem 1.5rem;
        margin-bottom: 0;
        font-size: 2rem;
    }

    &__card {
        display: flex;
        max-width: 20rem;
        margin-left: 2rem;
        cursor: pointer;
    }
}

.section {
    display: flex;
    gap: 2rem;
    margin-top: 5rem;

    &__updates {
        flex: 1;
        &__card {
            max-height: 350px;
            overflow-y: auto;
            padding: 1rem 3rem;
        }
    }
}
.ViewAll {
    color: #2ea96c;
    font-size: 1.5rem;
    cursor: pointer;
}
.Rfq {
    &__row {
        display: flex;
        align-items: stretch;
        gap: 8px;
    }

    &__tableControls {
        display: flex;
        // justify-content: flex-end;
        justify-content: space-between;
        height: 4rem;
    }
    &__tableControlsLeft {
        display: flex;
        gap: 1rem;
    }
    &__btns {
        display: flex;
        align-items: center;
        padding: 8px 16px;
        gap: 4px;
        color: #333;
        background-color: #fff;
        border-radius: 100px;
        border: 1px solid #e3e3e3;
    }
    &__transaction {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 16px;
        gap: 4px;

        color: #fff;
        background: #0e2c24;
        border-radius: 100px;
    }
}

.logEntry {
    border-bottom: 1px solid #ccc;
    padding: 6px;
}

.logEntry:last-child {
    border-bottom: none;
}

.logType {
    font-weight: bold;
    font-size: 1.6em;
    color: #333;
}

.logDate {
    font-weight: normal;
    font-size: 1.3em;
    color: #666;
}

.logDescription {
    font-size: 1.3em;
    color: #555;
}
