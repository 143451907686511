@use '../../assets/scss/mixins.scss' as mixins;

.ForgotPassword {
    display: flex;

    &__left {
        width: 30%;
        background-color: rgb(249, 251, 252);
    }

    &__right {
        flex-grow: 1;
        background-color: #ffffff;
    }

    &__side {
        height: 100vh;
        overflow: hidden;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        @include mixins.tablet {
            display: none;
        }
    }

    &__contentContainer {
        flex-grow: 1;
        // padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
    }

    &__logoContainer {
        display: flex;
        // justify-content: flex-end;
        width: 100%;
    }

    &__logo {
        padding: 1rem;
        height: 7rem;
        margin-bottom: 50px;
        margin-left: 40px;
        cursor: pointer;
    }

    &__form {
        width: 28.5rem;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

    &__formContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
    }

    &__formButtonGroup {
        display: flex;
        align-items: center;

        button {
            width: fit-content;
            font-size: 1.2rem;
            margin-right: 1rem;
            padding: 1rem 1.5rem;
        }
    }
    &__time {
        font-size: 1.4rem;
        font-weight: bold;
    }

    .ForgotPassword__left {
        width: 30%;
        background-color: rgb(249, 251, 252);
        padding: 1.7rem;
        height: 100vh;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            .listItem {
                margin-bottom: 1rem;
                font-size: 1.2rem;
                cursor: pointer;
                padding: 0.5rem 1rem;
                border-radius: 0.5rem;
                display: flex;
                align-items: center;
                font-size: 18px;
                margin-left: 40px;

                svg {
                    width: 1.5rem;
                    height: 1.5rem;
                    margin-right: 0.5rem;
                }

                &.active {
                    font-weight: bold;
                    color: #222;
                    font-size: 18px;

                    .iconPathActive {
                        fill: green;
                    }
                }

                &:hover {
                    background-color: #f0f0f0;
                }
            }
        }

        .iconPath {
            fill: #ccc;
        }
    }

    .ForgotPassword__right {
        background-color: #ffffff;
    }

    .titleContainer {
        display: flex;
        flex-direction: column;
        align-items: left;
        margin-top: 10px;
        padding-left: 30px;
    }

    .title {
        display: flex;
        align-items: center;
        color: #a2a4a8;
    }
    .title:active {
        color: #1c222a;
        font-weight: bold;
    }

    .subtitleContainer {
        margin-top: 4px;
        padding-left: 10px;
    }

    .subtitle {
        font-size: 12px;
        color: #8f9396;
        padding-left: 10px;
    }
    .iconPath {
        fill: none;
        stroke: black; // Set the default color to black
        transition: stroke 0.3s ease; // Add transition property
    }

    .iconPathActive {
        fill: none;
        stroke: green; // Adjust the active color as needed
    }

    .active {
        .iconPath {
            stroke: green; // Adjust the color for active state as needed
        }
    }

    .listItem:hover {
        .iconPath {
            stroke: green; // Adjust the color for highlighted state as needed
        }
    }

    &__title {
        text-align: center;
        margin-bottom: 1.5rem;
        font-weight: 600;
    }

    &__titleIcon {
        background: #00b9752d;
        width: 3.7rem;
        height: 3.7rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }
}
