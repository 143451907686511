.welcome_container {
    // height: 100vh;
    // width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    .welcome_content {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .welcome_heading {
            font-size: 36px;
            font-weight: bold;
            transition: transform 1s ease-in-out;
        }
        .welcome_text {
            font-size: 18px;
            margin-top: 20px;
        }
        .dashboard_button {
            width: fit-content;
        }
    }

    &:hover .welcome_heading {
        transform: scale(1.1); /* Zoom in on hover */
    }
}
