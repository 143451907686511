@mixin tablet {
    @media (max-width: 768px) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: 425px) {
        @content;
    }
}
@mixin Laptop {
    @media (max-width: 1097px) {
        @content;
    }
}
