.Loader {
    width: 100%;
    height: 100%;
    position: relative;

    &__wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &__buttonWrapper {
        visibility: hidden;
        margin-top: 4rem;
    }

    &__buttonVisible {
        visibility: visible;
    }
}
