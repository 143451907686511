@use '../../assets/scss/variables.scss' as variables;
@import '../SupplierProductCatalogue/SupplierProductCatalogue.module.scss';
.Content {
    padding: 0 6rem;
}
.Container{
    margin-left: 10px;
    margin-right: 10px;
}
.Header {
    min-height: variables.$header-height;
    background-color: white;
    display: flex;
    padding: 0 6rem;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #f0f1f5;
    &__title {
        font-weight: 600;
        font-size: 2rem;
    }
}

.Logo{
       margin-left: 40px;
       margin-top: 20px;
       margin-bottom: 20px;
      height: 50px;
      width: 160px;

}
.LogoContainer{
   
        display: flex;
        justify-content: space-between;

        h3 {
            cursor: pointer;
            margin-top: 20px;
        }
    
}
.ProductSelect__label{
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
}
.Enquiry_submit{
    margin-top: 20px;
}
.ProductRow {
    display: flex;
    align-items: center;
    margin-bottom: 1rem; /* Add space between rows */
}

.ProductRow__label {
    font-size: 14px;
    font-weight: 500;
    margin-top: 20px;
    margin-right: 1rem; /* Space between label and input */
    // flex: 1;
}

.QuantityInput {
    font-size: 12px;
    margin-top: 20px;
    width: 50px;
     height: 30px;
     padding-left: 10px;

}
