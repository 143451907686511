.Settings {
    margin-left: -5.6%;
    display: flex;
    justify-content: flex-start;
    &__Header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        cursor: pointer;
        padding: 1.8rem 2rem;
    }
    &__Header:hover {
        background-color: #f5f5f5;
    }
    &__Title {
        font-size: 1.8rem;
        font-weight: 500;
        margin: 0 1rem;
    }
    &__Icon {
        transform: scaleY(-1);
        transition: transform 0.3s ease-in-out;
    }
    &__Btn {
        margin-top: 1rem;
    }
    &__Content {
        border-top: 1px solid #e0e0e0;
        padding: 1.8rem 3rem;
        transition: 0.3s ease-in-out;
        // margin-left: 2rem;
    }
}

.Filter {
    font-size: medium;
}
.rightContainer {
    padding: 1.8rem 22rem;
    width: 100%;
    background-color: white;
    margin-top: 20px;
}
.Card {
    padding: 0;
}
