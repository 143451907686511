.Bid {
    max-width: 70rem;

    &__title {
        font-size: 22px;
        color: #333;
        margin-bottom: 8px;
        font-weight: bold;
    }

    &__subTitle {
        margin: 0;
        font-size: 1.1rem;
        text-transform: uppercase;
        font-weight: 500;
        color: grey;

        > span:first-child {
            margin-right: 0.2rem;
        }

        > span:last-child {
            margin-left: 0.2rem;
        }
    }

    &__organization {
        font-size: 1.4rem;
        margin: 0;
        margin-bottom: 1rem;
        margin-bottom: 1.5rem;
    }

    &__note {
        font-size: 1.4rem;
        margin: 0;
        margin-bottom: 1rem;
    }

    &__organization,
    &__note {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__main {
            display: block;
            font-size: 1.6rem;
            color: grey;
        }

        &__sub {
            display: block;
            font-weight: 600;
            font-size: 1.6rem;
            text-transform: capitalize;
        }
    }
    &__note:last-child {
        border-bottom: none;
    }
}
.Details {
    display: flex;
    gap: 1rem;
}

.BidButton {
    // Existing button styles
}

.BidPopup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    text-align: center;

    h2 {
        margin-bottom: 10px;
    }

    input {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-bottom: 20px;
    }

    .BidPopupButtons {
        display: flex;
        justify-content: center;

        button {
            margin: 0 10px;
            padding: 8px 16px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
        }
    }
}

.HighlightPrice {
    font-weight: bold;
}

.HighlightPromisedDate {
    font-weight: bold;
}
.AuctionLines {
    &__title {
        font-size: large;
        font-weight: 600;
    }
}
.DetailsContainer {
    display: flex;
    gap: 1rem;
}
.tlContent .tlHeader,
.tlContent .tlBody {
    padding-left: 25.6px;
    border-left: 2px solid gainsboro;
    margin-left: 5px;
}

.tlBody {
    padding-bottom: 0.6rem;
    font-size: 14px;
}

.tlAuthor {
    font-size: 15px;
    color: #333;
    font-weight: bold;
}

.tlTime {
    font-size: 11px;
    padding-left: 0.7rem;
    font-weight: lighter;
}
.tlHeader {
    position: relative;
    display: grid;
}

.tlMarker {
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50% / 50%;
    background: gainsboro;
    left: -0.9rem;
    top: 0.4rem;
    transform: translate(50%, -50%);
}
.commentForm {
    gap: 1rem;
    align-items: center;
    display: flex;
}
.Note {
    margin-top: 0.9rem;
    background-color: #f2f2f2;
    border-radius: 8px;
    width: fit-content;
    padding: 0.5rem 1rem;
}
.comment {
    width: 100%;
}
.timeline__Content {
    max-height: 12rem;
    overflow-y: auto;
}

.AuctionLinesHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.AuctionLines__counter {
    font-size: large;
    font-weight: bold;
    margin-right: 16px;
}
