@use '../../assets/scss/variables.scss' as variables;
@import '../SupplierProductCatalogue/SupplierProductCatalogue.module.scss';
.Content {
    padding: 0 6rem;
}
.Header {
    min-height: variables.$header-height;
    background-color: white;
    display: flex;
    padding: 0 6rem;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #f0f1f5;
    &__title {
        font-weight: 600;
        font-size: 2rem;
    }
}

.Logo{
       margin-left: 40px;
       margin-top: 20px;
       margin-bottom: 20px;
      height: 50px;
      width: 160px;

}
.LogoContainer{
   
        display: flex;
        justify-content: space-between;

        h3 {
            cursor: pointer;
            margin-top: 20px;
        }
    
}

.popup_label{
font-size: 14px;
font-weight: 400;
margin: 20px;
margin-left: 0px;
}

.popup_input{
width: 50px;
margin-left: 20px;
padding-left: 10px;
padding: 4px;
}

.btns{
    display: flex;
   
}
.Enquire{
background: #565555;

}

 
.cancel{
    background: #a8a8a8;
    margin-left: 50px;

}
.Categories{
    
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;
   
}
.CategoryCard{
    
    width: 200px;
    height: 160px;
    cursor: pointer;
    margin: 5px;
    overflow: hidden;
    background-image: linear-gradient(to right, 
    #a4da9b,
    #bdfff3);
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #f5f5f5;
    }
}

.CategoryCard.Selected{
    background-color: #f5f5f5; 
    border: 2px solid #b3b3b5;
}
.CategoryName{
    font-size: 14px;
    font-weight: 700;
}

.see_more{
    height: 30px;
    width: 100px;
    margin-top: 80px;
    background: rgb(235, 234, 234);
    color: black;
    border-radius: 20px;
}

.EnquiryButtonContainer {
    position: relative;
    display: inline-block;
}

.EnquiryBadge {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #e74c3c;
    color: white;
    border-radius: 50%;
    padding: 0.5em 0.9em;
    font-size: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Enquired {
    color: white;         
    cursor: not-allowed;   
    border: 1px solid #999; 
}