.TableControlSearch {
    &__searchContainer {
        display: flex;
        position: relative;
    }

    &__searchBoxWrapper {
        background-color: white;
        border-radius: 10rem;
        border: 1px solid #dcdcdc;
        overflow: hidden;
        display: flex;
        align-items: center;
        height: 100%;
        margin-right: 1rem;
    }

    &__searchBox {
        background-color: transparent;
        outline: none;
        height: 100%;
        border: none;
        font-size: 1.4rem;
        padding-right: 1rem;
    }

    &__searchBoxIcon {
        margin: 0 1rem 0 2rem;
    }
}
