.OrganizationBidDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f4f4f4;
    min-height: 150vh;
}

.Request__errorText {
    color: red;
    font-size: 18px;
}

.Details {
    font-size: 16px;
    margin-bottom: 0.2rem;
}

.bidNoteHead {
    font-size: 19px;
    color: #333;
    margin-bottom: 8px;
    font-weight: bold;
}

.Request {
    &__buttons {
        display: flex;
    }

    &__buttonWrapper {
        &:not(:last-child) {
            margin-right: 1rem;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        margin: 2.4rem 0;
    }
    &__descriptionTitle{
        margin-top: 20px;
    }
}

.Popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); // Semi-transparent background overlay
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; // Ensure the pop-up is above other content
}

// Styles for the pop-up content
.Popup__content {
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    max-width: 80%;
    overflow: auto; // Add scrollbars if content overflows
}

// Styles for the close button inside the pop-up
.Popup__close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
.Card {
    cursor: pointer;
}
.Cards {
    display: flex;
    gap: 1rem;
    &__left {
        width: 50%;
    }
    &__right {
        width: 50%;
    }
}

.tlContent .tlHeader,
.tlContent .tlBody {
    padding-left: 25.6px;
    border-left: 2px solid gainsboro;
    margin-left: 5px;
}

.tlBody {
    padding-bottom: 0.6rem;
    font-size: 14px;
}

.tlAuthor {
    font-size: 15px;
    color: #333;
    font-weight: bold;
}

.tlTime {
    font-size: 11px;
    padding-left: 0.7rem;
    font-weight: lighter;
}
.tlHeader {
    position: relative;
    display: grid;
}

.tlMarker {
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50% / 50%;
    background: gainsboro;
    left: -0.9rem;
    top: 0.4rem;
    transform: translate(50%, -50%);
}
.commentForm {
    gap: 1rem;
    align-items: center;
    display: flex;
}
.Note {
    margin-top: 0.9rem;
    background-color: #f2f2f2;
    border-radius: 8px;
    width: fit-content;
    padding: 0.5rem 1rem;
}
.comment {
    width: 100%;
}
.timeline__Content {
    max-height: 13rem;
    overflow-y: auto;
}
