@use '/node_modules/bootstrap/scss/bootstrap-grid.scss';

@use '../../assets/scss/mixins.scss' as mixins;

.CreateOrganization {
    // display: flex;

    &__logoContainer {
        padding: 2rem 2rem 0 2rem;
    }

    &__contentContainer {
        display: flex;
        // background-color: yellow;
        height: 100%;
        flex-direction: column;
    }

    &__title {
        margin-top: 2rem;
        margin-bottom: 4rem;

        &__main {
            display: block;
            font-size: 3.2rem;
            margin-bottom: 0.5rem;
            font-weight: bold;
        }

        &__sub {
            display: block;
            font-weight: 400;
            font-size: 1.6rem;
            color: #718096;
        }
    }

    &__formBtn {
        padding: 1.4rem;
        font-size: 1.6rem;
        background-color: #0038ff;
        font-weight: bold;
        color: white;
        border: none;
        margin-bottom: 1.4rem;
        display: flex;
        justify-content: center;
        width: 100%;
    }

    &__inputField {
        margin-bottom: 0.8rem;
    }
}
