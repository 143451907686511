.FormCheckbox {
    > div {
        display: flex;
        align-content: center;
    }

    &__msg {
        color: red;
        text-transform: capitalize;
        margin-top: 3px;
        font-weight: 500;
        display: block;
    }

    &__label {
        font-size: 1.2rem;
        margin-left: 0.5rem;
    }
}
