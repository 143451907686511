@use '../../assets/scss/mixins.scss' as mixins;

.Icon {
    display: flex;
    // color: white;
    color: #4a4a4a;
    padding: 1.2rem;
    font-size: 5px;
    // justify-content: space-between;
    background-color: transparent;
    outline: none;
    border: none;
    align-items: center;
    margin-bottom: 0.8rem;
    font-weight: 600;
    border-radius: 4px;

    &:hover {
        // background-color: rgb(10, 147, 117);
        background-color: #ececec;
        color: #0e2c24;
    }

    &__text {
        margin: 0;
        margin-left: 2rem;
        font-size: 1.4rem;
        display: none;

        @include mixins.tablet {
            display: none;
        }
    }

    &__imageWrapper {
        display: inline-block;
        width: 2rem;
    }

    &__imageWrapperNotExpanded {
        width: 100%;
    }

    &__image {
        height: 2rem;
    }

    &__expanded &__text {
        display: block;
    }

    &__active {
        background-color: #47e696 !important;
        color: #0e2c24 !important;
    }
    &__logout {
        color: red;
    }
}
