.InputWithError {
    &__input {
        // border: 2px solid #e2e8f0;
        font-size: 1.4rem;
        padding: 1.2rem;
        outline: none;
        display: block;
        // border-radius: 8px;
        border: none;
    }
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &__msg {
        color: #ff0000;
        text-transform: capitalize;
        margin-top: 3px;
        font-weight: 500;
        display: block;
        font-family: inherit;
        line-height: 1.8;
    }

    &__label {
        font-size: 1.4rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
        margin-top: 1rem;
    }

    &__inputWrapper {
        display: flex;
        border: 2px solid #e2e8f0;
        font-size: 1.4rem;
        outline: none;
        border-radius: 8px;
        overflow: hidden;
        width: fit-content;
    }
}
.Btn {
    padding: 1rem 2rem;
    border: none;
}
