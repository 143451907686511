@use '/node_modules/bootstrap/scss/bootstrap-grid.scss';

.AddProduct {
    &__title {
        margin-top: 0rem;
        font-size: 1.8rem;
        font-weight: bold;
        margin: 2rem 0;
    }

    &__inputField {
        font-size: 1.4rem;
    }

    &__imageWrapper {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
        grid-template-rows: repeat(auto-fit, 20rem);
        grid-auto-flow: dense;
        grid-gap: 0.3rem;
        position: relative;
    }

    &__image {
        height: 20rem;
        width: 20rem;
        background-color: green;
        position: relative;
    }
    &__cancelButton {
        background-color: white;
        color: black;
        border: 2px solid darkgray;
    }
    &__cancelButton:hover {
        background-color: #f2f2f2;
    }
}
.Btn__Container {
    display: flex;
    margin-top: 1rem;
    gap: 1rem;
}



.DocumentItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.RemoveButton {
    background: rgb(214, 74, 74);
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
}
.DocRemoveButton {
    background: rgb(214, 74, 74);
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    
}
.imgbtn {
    position: relative; // Ensure child elements are positioned relative to this container
    display: inline-block;
}

.ImgRemoveButton {
    position: absolute;
    top: 5px; // Adjust as needed
    right: 5px; // Adjust as needed
    background: rgb(214, 74, 74);
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 1.2rem;
    z-index: 1; // Ensure button is above the image
}

.RemoveButton:hover {
    background: rgb(255, 100, 100); // Add hover effect
}


.hiddenInput {
    display: none; /* Hide the default file input */
}

.customFileInput {
    margin-top: 13px;
    align-items: center;
    gap: 10px;
}

.customButton {
    color: rgb(27, 187, 107);
    background-color: rgb(237, 248, 243);
padding: 5px;
    border: none;
    margin: 7.5px;
    border-radius: 4px;
    cursor: pointer;
  font-size: 13px;
}

.customButton:hover {
    background-color: rgb(27, 187, 107);
    color:  rgb(237, 248, 243);
   
}

.fileName {
    font-size:15px;
    color: #000000;
    margin-top: 10px;
}
.fileInput{
    
    display: flex;
    border: 2px solid #e2e8f0;
 
    font-size: 1.4rem;
    outline: none;
   
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}
.Doclabell{
    margin-top: 28px;
    
}
