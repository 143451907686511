.FormSelect {
    &__inputField {
        font-size: 1.4rem;
        height: 100%;
        &__filter {
            margin-right: 1rem;
        }
    }

    &__msg {
        color: red;
        text-transform: capitalize;
        margin-top: 3px;
        font-weight: 500;
        display: block;
    }

    label {
        font-size: 1.4rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
        margin-top: 1rem;
    }
}
