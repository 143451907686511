.ClientDetails__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
}

.Card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #e3e3e3;
}

.Request__organizationDetails {
    background-color: #231709;
    color: white;
    padding: 1.4rem 2rem;
    border-radius: 10px 10px 0 0;
    font-size: 1.6rem;
    font-weight: 600;
}

.Request__organizationContents {
    padding: 1.4rem 2rem;
}

.Request__text {
    display: flex;
    justify-content: space-between;
    font-size: 1.6rem;
    color: grey;
    margin-bottom: 1rem;

    span:nth-child(2) {
        color: black;
        text-transform: capitalize;
    }
}

.Request__organizationName {
    font-weight: bold;
}
