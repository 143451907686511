.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
}

.addTemplateButton {
    margin-bottom: 20px;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 500px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.field {
    width: 100%;
    margin-bottom: 20px;
}
.Products {
    &__top {
        // margin: 1rem 0 2rem 0;
        display: flex;
        justify-content: space-between;
    }

    &__rightContainer {
        display: flex;
        align-items: stretch;
        gap: 1rem;
    }
    &__view {
        padding: 1rem;
        border-radius: 7px;
    }
    &__view:hover {
        cursor: pointer;
        background: #000;
        background-color: rgba(0, 0, 0, 0.096);
    }
}
.Table {
    width: 100%;
    background-color: white;
    border-radius: 12px;
    border-collapse: collapse;
    padding: 1rem;
    // min-width: 40rem;
    overflow-x: auto;

    tr {
        text-align: left;
        position: relative;
    }

    thead tr th {
        font-weight: 400;
        color: #5a5a5a;
    }

    thead tr th,
    tbody tr td {
        padding: 1rem;
        font-size: 1.4rem;
    }

    tbody tr:nth-child(odd) {
        background-color: #f9f9f9;
    }

    tbody tr td:nth-child(1),
    tbody tr td:nth-child(3) {
        font-weight: 500;
    }

    thead tr th:last-child,
    tbody tr td:last-child {
        text-align: right;
    }

    &__dropdownContainer {
        position: relative;
        height: 100%;
    }

    &__dropdown {
        position: fixed;
        right: 15rem;
        z-index: 1;
        list-style-type: none;
        font-size: 1.2rem;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
        padding: 0;
        border-radius: 4px;
        overflow: hidden;
        background-color: white;
        width: 10rem;

        text-align: left;

        li {
            padding: 0.5rem;
            cursor: pointer;
            &:hover {
                background-color: rgb(244, 244, 244);
            }
        }
    }

    &__dropdownButton {
        background-color: transparent;
        outline: none;
        border: none;
        height: 100%;
        display: block;
    }
}

.galleryContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 16px;
}
.galleryItem {
    cursor: pointer;
    transition: all 300ms;
    &:hover {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }
}
.galleryItemHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.galleryItemPrice {
    color: #00b976;
    font-size: 1.5rem;
}

.galleryItemImage {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-bottom: 1px solid #ddd;
}

.galleryItemTitle {
    font-size: 1.7rem;
    font-weight: bold;
    margin-bottom: 8px;
    text-transform: capitalize;
}

.galleryItemDetails {
    color: #555;
    font-size: 1.5rem;
}

.deletedMessage {
    margin-top: 5px;
    position: relative;
    background-color: #f44336;
    color: white;
    padding: 6px;
    border-radius: 5px;
    margin-bottom: 5px;
    text-align: center;
}

.closeButton {
    position: absolute;

    top: 2px;
    right: 5px;
    cursor: pointer;
    font-size: 14px;
    color: white;
    padding-right: 10px;

    &:hover {
        color: #ccc;
    }
}

.Cards {
    display: flex;
    margin-bottom: 1rem;

    &__card {
        padding-bottom: 30px;
        flex-grow: 1;
        &:not(:last-child) {
            margin-right: 1rem;
        }

        // &:last-child {
        //     flex-grow: 0.5;
        // }
    }

    &__createNewCard {
        background-color: #7800ef;
        color: white;
    }
}

.label {
    font-size: 1.2rem;
    margin-bottom: 8px;
    font-weight: bold;
    display: block;
}

.labelDesc {
    font-size: 1.2rem;
    margin-bottom: 8px;
    font-weight: bold;
    display: block;
}

.text {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.area {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}

.buttonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.error {
    color: red;
    font-size: 0.9rem;
    margin-top: 10px;
}
