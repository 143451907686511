@use '/node_modules/bootstrap/scss/bootstrap-grid.scss';

.Supplier {
    &__row {
        display: flex;
        align-items: stretch;
    }

    &__tableControls {
        display: flex;
        // justify-content: flex-end;
        justify-content: space-between;
    }

    &__buttonDropWrapper {
        position: relative;
        ul {
            position: absolute;
            top: 110%;
            right: 0.5rem;
        }
    }
}

.Modal {
    &__formCheckbox {
        input {
            align-self: flex-start;
            margin-top: 0.3rem;
        }
    }

    &__bottom {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
    }
}

.Cards {
    display: flex;
    margin-bottom: 1rem;

    &__card {
        z-index: 1;
        position: relative;
        flex-grow: 1;
        padding-bottom: 30px;
        &:not(:last-child) {
            margin-right: 1rem;
        }

        &:last-child {
            flex-grow: 0.5;
        }
    }

    &__formButtonGroup {
        display: flex;
        align-items: center;

        button {
            width: fit-content;
            font-size: 1.2rem;
            margin-right: 1rem;
            padding: 1rem 1.5rem;
        }
    }

    &__createNewCard {
        color: #1bbb6b;
        border: 3px solid #1bbb6b;
        background-color: 'transparent';
        cursor: pointer;

        &--active {
            background-color: #1bbb6b;
            color: white;
        }
    }
}

.Table {
    width: 100%;
    background-color: white;
    border-radius: 12px;
    border-collapse: collapse;
    padding: 1rem;
    // min-width: 40rem;
    overflow-x: auto;

    tr {
        text-align: left;
        cursor: pointer;
    }

    tbody tr {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            // background-color: green;
            width: 100%;
            height: 100%;
            border-left: 4px solid transparent;
            z-index: 1;
            transition: all 100ms ease;
            border-radius: 4px;
        }

        &:hover::after {
            border-left: 4px solid lightcoral;
        }
    }

    thead tr th {
        font-weight: 400;
        color: #5a5a5a;
    }

    thead tr th,
    tbody tr td {
        padding: 1rem;
        font-size: 1.4rem;
    }

    tbody tr:nth-child(odd) {
        background-color: #f9f9f9;
    }

    tbody tr td:nth-child(1),
    tbody tr td:nth-child(3) {
        font-weight: 500;
    }

    thead tr th:last-child,
    tbody tr td:last-child {
        text-align: right;
    }
}
.invite {
    padding: 12px 16px;
    border: none;
    background-color: #979797;
    border-radius: 4px;
    cursor: pointer;
}

* {
    margin: 0;
    padding: 0;
}

.App {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    width: 800;
    height: 300;
    margin: 100px auto;
}

.btn {
    margin-top: 20px;
    padding: 10px 30px;
    cursor: pointer;
}
