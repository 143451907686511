@use '../../assets/scss/variables.scss' as variables;

.HeaderLogo {
    min-height: variables.$header-height;
    display: flex;
    align-items: center;
    padding-left: 4.5rem;
    border: 1px solid #f0f1f5;

    img {
        cursor: pointer;
    }
}
