.Product {
  

    &__detailsWrapper {
       
        display: flex;
        justify-content: space-between;
        overflow: hidden;
    }

    &__detailsTitle {
        font-size: 3rem;
        font-weight: 600;
        text-transform: capitalize;
    }

    &__detailsValue {
        font-size: 1.8rem;

    }

    &__detailsDescription {
        font-size: 1.6rem;
        margin-bottom: 1.5rem;

        &__main {
            display: block;
            font-weight: 600;
        }

        &__sub {
            display: block;
            font-size: 1.4rem;
        }
    }

    &__details {
        margin: 0;
        font-size: 1.5rem;
        text-transform: capitalize;
        font-weight: 500;
        color: grey;
    }

    &__detailsText {
        font-size: 1.4rem;
        margin: 0;
        margin-bottom: 1rem;

        &__main {
            display: block;
            font-weight: 600;
        }

        &__sub {
            display: block;
            font-size: 1.4rem;
        }
    }

    &__imagesWrapper {
        margin-right: 1rem;
        width: 40rem;
    }

    &__imageWrapper {
         &__active {
            border: 2px solid rgb(196, 196, 196);
        }
    }

    &__image {
      width: auto;
      
      left: 0;
      align-items: center;  
    }

    &__mainImageWrapper {
       overflow: hidden;
       margin-bottom: 2rem;
    }

    &__mainImage {
        height: 100%;
        width: auto;
        margin: 0 auto;
        display: block;
    }

    &__deleteButton {
        border: 1px solid red;
        background-color: white;
        color: red;
    }
    &__deleteButton:hover {
        background-color: #f44336;
        color: white;
    }
}

.Btn__wrapper {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.Document

{
    &__name{
        font-size: 15px;
    }
&__downloadLink{
    text-decoration: none;
    margin-left: 50px;
    cursor: pointer;
}
}

.Images{
    height: auto;
}
.imagediv{
    margin-top: 50px;
}